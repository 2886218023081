import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
	Table,
	TableCell,
	TableBody,
	TableHead,
	TableRow,
	TableContainer,
} from "@material-ui/core";
import { useKeywords } from "../../hooks/useKeywords";
import { getKeywordData } from "../../services/mangools";
import API from "../../services/API";
import Popover from "@material-ui/core/Popover";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "aliceblue",
		marginBottom: "20px",
		border: "1px solid cadetblue",
		padding: "0 10px",
	},
	popover: {
		pointerEvents: "none",
	},
	paper: {
		padding: theme.spacing(1),
	},
}));

const KeywordDomainDisplay = () => {
	const classes = useStyles();
	const {
		keyword,
		setActiveKeyword,
		keywordsData,
		url,
		locationData,
		keywords,
		setKeywordsData,
	} = useKeywords();
	const [kwDomainData, setKwDomainData] = React.useState();
	const [kwTop1Data, setKwTop1Data] = React.useState();
	const fetchKeywords = async (kw) => {
		const paramGet = {
			url: url,
			locationId:
				JSON.stringify(locationData && locationData.mangools) || "2702",
			keyWords: kw,
		};
		const resApiData = await API.getMangoolsKeywork(paramGet);
		const lastTwoWeek = new Date() - 12096e5;
		const dateUpdateData = new Date(
			resApiData && resApiData[0] ? resApiData[0].resultDate : "null"
		).getTime();
		if (
			resApiData &&
			resApiData[0] &&
			dateUpdateData > lastTwoWeek &&
			resApiData[0].keyWordsResult != "undefined"
		) {
			return JSON.parse(resApiData[0].keyWordsResult);
		} else if (resApiData && resApiData[0]) {
			const keyword = kw;
			const location = locationData.mangools;
			return await getKeywordData(url, keyword, location)
				.then((kwdata) => {
					const param = new URLSearchParams({
						_id: resApiData[0]._id,
						keyWordsResult: JSON.stringify(kwdata),
					});
					API.updateMangoolsKeywork(param)
						.then((response) => {})
						.catch((e) => {
							console.log(e);
						});
					return kwdata;
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			return await getKeywordData(url, kw, locationData.mangools)
				.then((kwdata) => {
					let data = kwdata;
					if (kwdata) {
						const param = new URLSearchParams({
							url: url,
							locationId: JSON.stringify(locationData.mangools),
							keyWords: kw,
							keyWordsResult: JSON.stringify(data),
						});
						API.createMangoolsKeywork(param)
							.then((response) => {})
							.catch((e) => {
								console.log(e);
							});
						return kwdata;
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	useEffect(() => {
		(async () => {
			const data = await fetchKeywords(url);
			setKwDomainData(data);
		})();
	}, [url]);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [contentPopup, setContentPopup] = React.useState({
		title: <CircularProgress />,
		description: <CircularProgress />,
	});
	const open = Boolean(anchorEl);

	const handlePopoverOpen = (event, url) => {
		const param = { url: url };
		API.getTitleAndDesc(param).then((response) => {
			// const content = "Title: " + response.title + "<hr> Description: " + response.description;
			setContentPopup(response);
		});
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
		setContentPopup({
			title: <CircularProgress />,
			description: <CircularProgress />,
		});
	};

	// useEffect(() => {
	//     (async () => {
	//         const urlTop1 = keyword.items.filter((e) => e.url !== url)[0].url;
	//         const dataTop1 = await fetchKeywords(urlTop1);
	//         // console.log(keyword);
	//         // console.log(urlTop1);
	//         if (dataTop1) {
	//             setKwTop1Data(dataTop1);
	//         }
	//     })();
	// }, [keyword]);

	return (
		<div className={classes.root}>
			<div>
				<Popover
					id="mouse-over-popover"
					className={classes.popover}
					classes={{
						paper: classes.paper,
					}}
					open={open}
					anchorEl={anchorEl}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "left",
					}}
					onClose={handlePopoverClose}
					disableRestoreFocus
				>
					<h4>
						<b>Title: </b> {contentPopup.title}
					</h4>
					<p>
						<b>Description: </b> {contentPopup.description}
					</p>
				</Popover>
			</div>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<h2>Keyword: {url} </h2>
			</div>
			<TableContainer>
				<Table className="table-meta">
					<TableHead>
						<TableRow>
							<TableCell>URL</TableCell>
							<TableCell>DA</TableCell>
							<TableCell>PA</TableCell>
							<TableCell>TF</TableCell>
							<TableCell>CF</TableCell>
							<TableCell>Links</TableCell>
							<TableCell>FB</TableCell>
							<TableCell>LPS</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow hover={true}>
							<TableCell>
								{/* <a
									href={kwDomainData ? kwDomainData.url : "#"}
									rel="nofollow external noopener noreferrer"
									target="_blank"
								>
									{kwDomainData
										? kwDomainData.url
										: "Loading..."}
								</a> */}
								<a
									aria-owns={
										open ? "mouse-over-popover" : undefined
									}
									aria-haspopup="true"
									onMouseEnter={(e) =>
										handlePopoverOpen(
											e,
											kwDomainData ? kwDomainData.url : ""
										)
									}
									onMouseLeave={handlePopoverClose}
									href={kwDomainData ? kwDomainData.url : ""}
									target="_blank"
								>
									{kwDomainData
										? kwDomainData.url
										: "Loading..."}
								</a>
							</TableCell>
							<TableCell>
								{kwDomainData && kwDomainData.meta
									? kwDomainData.meta.pda
									: 0}
							</TableCell>
							<TableCell>
								{kwDomainData && kwDomainData.meta
									? kwDomainData.meta.upa
									: 0}
							</TableCell>
							<TableCell>
								{kwDomainData && kwDomainData.meta
									? kwDomainData.meta.tf
									: 0}
							</TableCell>
							<TableCell>
								{kwDomainData && kwDomainData.meta
									? kwDomainData.meta.cf
									: 0}
							</TableCell>
							<TableCell>
								{kwDomainData && kwDomainData.meta
									? kwDomainData.meta.links
									: 0}
							</TableCell>
							<TableCell>
								{kwDomainData && kwDomainData.meta
									? kwDomainData.meta.fb
									: 0}
							</TableCell>
							<TableCell>
								{kwDomainData && kwDomainData.meta
									? kwDomainData.meta.lps
									: 0}
							</TableCell>
						</TableRow>

						{/* <TableRow hover={true}>
                            <TableCell>
                                <a
                                    href={kwTop1Data ? kwTop1Data.kw : "#"}
                                    rel="nofollow external noopener noreferrer"
                                    target="_blank"
                                >
                                    {kwTop1Data ? kwTop1Data.kw : "Loading..."}
                                </a>
                            </TableCell>
                            <TableCell>
                                {kwTop1Data && kwTop1Data.meta
                                    ? kwTop1Data.meta.pda
                                    : 0}
                            </TableCell>
                            <TableCell>
                                {kwTop1Data && kwTop1Data.meta
                                    ? kwTop1Data.meta.upa
                                    : 0}
                            </TableCell>
                            <TableCell>
                                {kwTop1Data && kwTop1Data.meta
                                    ? kwTop1Data.meta.tf
                                    : 0}
                            </TableCell>
                            <TableCell>
                                {kwTop1Data && kwTop1Data.meta
                                    ? kwTop1Data.meta.cf
                                    : 0}
                            </TableCell>
                            <TableCell>
                                {kwTop1Data && kwTop1Data.meta
                                    ? kwTop1Data.meta.links
                                    : 0}
                            </TableCell>
                            <TableCell>
                                {kwTop1Data && kwTop1Data.meta
                                    ? kwTop1Data.meta.fb
                                    : 0}
                            </TableCell>
                            <TableCell>
                                {kwTop1Data && kwTop1Data.meta
                                    ? kwTop1Data.meta.lps
                                    : 0}
                            </TableCell>
                        </TableRow> */}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
};

export default KeywordDomainDisplay;
